//nodemodules & packages

$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 960px,
  lg: 1200px,
  xl: 1600px
);

// voor grid & spacers
@import '~bootstrap/scss/bootstrap-grid.scss';
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~glightbox/dist/css/glightbox.css";
